const complianceCookieName = 'compliance-20211019'

const modalSources = {
  '#agb': '/src/partials/agb.html',
  '#datenschutz': '/src/partials/datenschutz.html',
  '#imprint': '/src/partials/impressum.html',
}

const trackerSources = [
  'https://www.visableleads.com/v1/cp/750898bff28d56168a2a5520c74b3974',
]

export default {
  complianceCookieName,
  modalSources,
  trackerSources,
}
