import Cookies from 'js-cookie'
import cookieNoticeHTML from './cookie-notice.html'

let config = {
  cookieName: '',
  complianceCallback: () => {},
  denieCallback: () => {},
}

export const dontComply = () => {
  Cookies.set(config.cookieName, 'denie', {
    SameSite: 'Strict',
    expires: 28,
  })
  config.denieCallback()
}

export const doComply = () => {
  Cookies.set(config.cookieName, 'accept', {
    SameSite: 'Strict',
    expires: 28,
  })
  config.complianceCallback()
}

export const toggleComply = () => {
  isComply() ? dontComply() : doComply()
}

export const isComply = () => {
  return 'accept' == Cookies.get(complianceCookieName)
}

export const cookieCompliance = (cookieName, complianceCallback, denieCallback) => {
  config.cookieName = cookieName
  config.denieCallback = denieCallback
  config.complianceCallback = complianceCallback

  switch (Cookies.get(cookieName)) {
    case 'accept':
      complianceCallback()
      break
    case 'denie':
      // nothing to do
      denieCallback()
      break
    default:
      $('body').append(cookieNoticeHTML)

      $('#cookie-denie').on('click', () => {
        dontComply()
        $('#cookie-notice').addClass('hide')
      })
      $('#cookie-accept').on('click', () => {
        doComply()
        complianceCallback()
        $('#cookie-notice').addClass('hide')
      })
      break
  }
}
