import createNodeFromHTML from '../methods/createNodeFromHTML'
import modalHTML from './modal.html'
import modalBackdropHTML from './modalBackdrop.html'

const nodes = {
  body: null,
  modal: null,
  backdrop: null,
}

export const closeModal = () => {
  ensureModal()
  nodes.body.classList.remove('modal-open')
  window.setTimeout(doCloseModal, 500)
}

const doOpenModal = () => {
  nodes.body.classList.add('modal-open')
}

const doCloseModal = () => {
  nodes.backdrop.style.display = 'none'
  nodes.modal.style.display = 'none'
}

export const openModal = () => {
  ensureModal()
  nodes.backdrop.style.display = 'block'
  nodes.modal.style.display = 'block'
  window.setTimeout(doOpenModal, 100)
}

export const ensureModal = () => {
  if (null == nodes.body) {
    nodes.body = document.getElementsByTagName('body')[0]
  }

  if (null == nodes.modal) {
    nodes.modal = createNodeFromHTML(modalHTML)
    nodes.modal
      .querySelectorAll('[data-dismiss]')
      .forEach((el) => el.addEventListener('click', closeModal))
    nodes.modal.addEventListener('click', (event) => {
      if (event.target === nodes.modal) {
        closeModal()
      }
    })
    nodes.body.append(nodes.modal)
  }

  if (null == nodes.backdrop) {
    nodes.backdrop = createNodeFromHTML(modalBackdropHTML)
    nodes.backdrop.addEventListener('click', closeModal)
    nodes.body.append(nodes.backdrop)
  }
}

export const setModalBody = (bodyNode) => {
  let modalFooter = null,
    modalBodyOld = null

  ensureModal()
  modalFooter = nodes.modal.querySelector('.modal-footer')
  modalBodyOld = nodes.modal.querySelector('.modal-body')

  if (modalBodyOld) {
    modalBodyOld.remove()
  }

  modalFooter.parentNode.insertBefore(bodyNode, modalFooter)
}
