export default function theScroller() {
  if ($(window).scrollTop() > 50) {
    $('#navbardyn').addClass('navbar-scrolled')
    $('#navbar_logo').addClass('logo1-scrolled')
    $('#navbar_logo2').addClass('logo2-scrolled')
  } else {
    $('#navbardyn').removeClass('navbar-scrolled')
    $('#navbar_logo').removeClass('logo1-scrolled')
    $('#navbar_logo2').removeClass('logo2-scrolled')
  }
}
