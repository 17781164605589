import hookUpTracker from './hookUpTracker'
import sideBarClick from './sideBarClick'
import theScroller from './theScroller'
import config from './config'
import { cookieCompliance } from './cookieCompliance'
import fetchModal from './fetchModal'
import { ensureModal } from './modules/modal'

export default function setUp() {
  const theBody = document.getElementsByTagName('body')[0]

  // document.querySelectorAll('.product-sidebar a').forEach((el) => {
  //   el.addEventListener('click', sideBarClick)
  // })

  window.addEventListener('scroll', theScroller)

  cookieCompliance(
    config.complianceCookieName,
    () => {
      hookUpTracker()
      theBody.classList.remove('denied')
      theBody.classList.add('complied')
    },
    () => {
      theBody.classList.add('denied')
      theBody.classList.remove('complied')
    }
  )

  document.querySelectorAll('a[data-fetch-modal]').forEach((el) => {
    el.addEventListener('click', fetchModal)
  })
  ensureModal()

  // uncomment for fast open of modal
  // document.querySelectorAll('a[data-fetch-modal]')[0].click()
}
