import config from './config'

export default function hookUpTracker() {
  const firstScript = document.getElementsByTagName('script')[0]

  config.trackerSources.forEach((source) => {
    let newScript = document.createElement('script')

    newScript.async = true
    newScript.type = 'text/javascript'
    newScript.src = source

    firstScript.parentNode.insertBefore(newScript, firstScript)
  })
}
