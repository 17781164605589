import config from './config'
import { doComply, dontComply } from './cookieCompliance'
import createNodeFromHTML from './methods/createNodeFromHTML'
import { openModal, setModalBody } from './modules/modal'

const fetchModal = (event) => {
  let modalSource = config.modalSources[event.currentTarget.dataset.fetchModal]

  fetch(modalSource)
    .then((response) => response.text())
    .then((modalBody) => {
      let modalBodyNode = createNodeFromHTML(modalBody)

      setModalBody(modalBodyNode)

      modalBodyNode.querySelectorAll('button[data-denie]').forEach((el) => {
        el.addEventListener('click', dontComply)
      })
      modalBodyNode.querySelectorAll('button[data-accept]').forEach((el) => {
        el.addEventListener('click', doComply)
      })

      openModal()
    })
}

export default fetchModal
